export const NAME = "Emerson Brôga Dev";
export const BASE_URL = "https://js.emersonbroga.com";

// eslint-disable-next-line
export const BOOK_ES6 = `JavaScript As Novidades do ES6`;

export const CONVERTKIT_FORM_ID_SUBSCRIPTION = "1617146";
export const CONVERTKIT_FORM_ID_NEWSLETTER = "1817527";
export const CONVERTKIT_FORM_ID_DICAS_JAVASCRIPT = "1817527"; // change it.
export const CONVERTKIT_FORM_ID_ES6_SAMPLE = "1909034";
export const CONVERTKIT_FORM_ID_JAN_2021 = "1948650";
export const CONVERTKIT_FORM_ID_APR_2021 = "2106688";
export const CONVERTKIT_FORM_ID_SEP_2021 = "2542760";

export const whatsAppUrls = [
  // "https://chat.whatsapp.com/G10z1Y6HuPFEVeBrOxRP5Y", // Broga no Zap 1
  // "https://chat.whatsapp.com/LE7tliY6T0vDTsVGiP6CQ7", // Broga no Zap 2
  "https://chat.whatsapp.com/Hrv6r6ERcgb9vW1v9uZRxK", // Broga no Zap 3
  "https://chat.whatsapp.com/F2xznAla2WtHwV9mpFXcgt", // Broga no Zap 4
  "https://chat.whatsapp.com/Gh4pYbaeplBITx8x1njh3H", // Broga no Zap 5
];

export const FACEBOOK_APP_ID = "2256051657752066";
export const YOUTUBE_CHANNEL_ID = "UC29n3f6JhwqtD-kCJi_BwoA";

export const PROJECT_DEV_2021 = "Projeto Dev 2021";

export const MARATONA_JS_2 = "Maratona JS 2";
export const MARATONA_JS_3 = "Maratona JS 3";

export const PROJECT_DEV_2021_DATES = [
  {
    key: "day-1",
    start: new Date("2021-01-25T08:00:00"),
    day: "25",
    month: "01",
    year: "2021",
    title: "Project 2021",
    canonical: `${BASE_URL}/dev-2021/day-1`,
    relative: `/dev-2021/day-1`,
  },
  {
    key: "day-2",
    start: new Date("2021-01-27T08:00:00"),
    day: "27",
    month: "01",
    year: "2021",
    title: "Project 2021",
    canonical: `${BASE_URL}/dev-2021/day-2`,
    relative: `/dev-2021/day-2`,
  },
  {
    key: "day-3",
    start: new Date("2021-01-29T08:00:00"),
    day: "29",
    month: "01",
    year: "2021",
    title: "Project 2021",
    canonical: `${BASE_URL}/dev-2021/day-3`,
    relative: `/dev-2021/day-3`,
  },
];
